$brk: 1300px;
$text-color: #5e5e5e;
$brand-color: #353E5C;

@mixin page-width() {
  width: 100%;

  @media screen and (min-width: $brk) {
    width: $brk;
    margin: 0 auto;
  }
}

@mixin when-big() {
  @media screen and (min-width: $brk) {
    @content;
  }
}

@mixin till-big() {
  @media screen and (max-width: $brk) {
    @content;
  }
}

.page {
  color: $text-color;
  font-size: 16px;
  line-height: 1.8;

  @include when-big() {
    font-size: 20px;
    line-height: 1.5;
  }

  img {
    max-width: calc(100% - 20px);
    margin: 0 10px;

    @include when-big() {
      max-width: none;
    }
  }
}

.navbar {
  background-color: $brand-color;
  color: #fff;
  padding: 20px;
  border-bottom: 1px solid #000;
}

.headerInner {
  @include page-width();
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
}

.logo {
  height: 40px;
}

.hero {
  height: 850px;
  background-image: url("./assets/bg-top.jpg");
  background-size: cover;
  color: #FFF;
  position: relative;

  background-position: center;
  @include when-big() {
    background-position: right;
  }

  &:after {
    @include till-big {
      content: '';
      display: block;
      background: rgba(#000, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.heroContent {
  @include page-width();

  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 999;

  align-items: center;

  @include when-big() {
    align-items: flex-end;
  }

  h1 {
    font-size: 2.5em;
    line-height: 2.8rem;
  }

  h2 {
    font-size: 1.8em;
    font-weight: 300;
  }

  h1, h2 {
    margin: 3px;
    padding: 0;
  }

  img {
    margin-top: 20px;
  }
}

.bigLogo {
  height: 100px;
  margin-left: 6px;
}

.heroContentTexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include when-big() {
    width: 600px;
    align-items: flex-start;
    text-align: left;
  }
}

.page {
  position: relative;
  padding-top: 80px;

  &:before {
    display: block;
    content: "";
    transform: scaleY(-1);
    background-image: linear-gradient(180deg, rgba(254, 254, 254, 0.00) 0%, rgba(0, 0, 0, 0.25) 100%);
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.pageInner {
  min-height: 500px;
  text-align: center;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include page-width;

  margin-bottom: 40px;

  @include when-big() {
    margin-bottom: 70px;
  }
}

.cover {
  margin-top: 15px;
}

.companyBio {
  font-size: 1em;
  font-weight: 300;
  margin: 50px 20px 40px 20px;

  @include when-big() {
    width: 70%;
  }

  a {
    color: inherit;
  }
}

.heading {
  font-size: 2em;
  font-weight: 100;
  margin: 0 20px;

  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background-color: $text-color;
    margin: 0 auto;
    margin-top: 30px;
  }

  h2 {
    background-image: linear-gradient(180deg, #A31CBA 0%, #405BE4 100%);
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    font-size: 1.4em;
    line-height: 1.4em;
  }
}

.s-bio {
  margin-bottom: 60px;
}

.contact {
  margin-top: 180px;
  background-color: #5e5e5e;
  background-image: url('./assets/bg-bottom.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &:after {
    content: '';
    display: block;
    background: rgba(#000, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
  }
}

.contactForm {
  position: relative;
  z-index: 999;
  background-color: #56B71D;
  border-radius: 20px;
  color: #FFF;
  text-align: left;
  padding: 20px 20px;
  font-size: 1em;
  margin-top: -100px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 0px 60px rgba(#FFF, 0.3);
  border: 1px solid rgba(#FFF, 0.2);

  @media screen and (min-width: 800px) {
    width: 800px;
    padding: 20px 40px;
  }

  > * {
    margin: 10px 0;
  }

  h2 {
    margin-bottom: 40px;
  }

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
}

.bottom {
  margin-bottom: 0;
  padding-bottom: 0;
  height: 600px;
  position: relative;
}

.contactRow {
  display: flex;
  flex-direction: column;

  @include when-big() {
    flex-direction: row;
  }
}

.contactCol {
  display: flex;
  flex-direction: column;

  @include when-big() {
    width: 50%;
  }
}

.copy {
  position: absolute;
  bottom: 10px;
  left: 0; right: 0;
  margin: auto;
  @include page-width();
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7em;
  display: flex;
  z-index: 400;

  flex-direction: column;
  @include when-big() {
    flex-direction: row;
  }

  a {
    color: inherit;
  }

  &Col {
    @include when-big() {
      width: 50%;
    }

    &:first-child {
      text-align: center;

      @include when-big() {
        text-align: left;
      }
    }

    &:last-child {
      text-align: center;

      @include when-big() {
        text-align: right;
      }
    }
  }
}
